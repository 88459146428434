import * as React from 'react';
import { getTemplateSrv, config } from '@grafana/runtime';
import { translate, TranslationProps } from './translation';
import { ThemeContext } from '@grafana/ui';
import { AppState } from './AppContext/types';

const filterTemplateCurrentText = (text: string) => {
  const getTemplate = getTemplateSrv().getVariables();
  const template = getTemplate && getTemplate.filter((template) => template.name === text);
  return template;
};

/**
 * Initialize default state and pass it to the main app component
 */

export const withData = (
  WrappedComponent: React.ComponentType<React.PropsWithChildren<{ initialState: AppState }>>
) => {
  // eslint-disable-next-line react/display-name
  return ({ children }: React.PropsWithChildren<{}>) => {
    const currentTheme = React.useContext(ThemeContext);
    const userPath = config.bootData.user;
    const langTemplate = filterTemplateCurrentText('lang_selection') as any;
    const beaconTemplate = filterTemplateCurrentText('beacon_selection') as any;
    const lengthUnitTemplate = filterTemplateCurrentText('length_unit') as any;
    const macAddress = beaconTemplate[0]?.current?.value;

    let langSelect = !langTemplate.length ? 'EN' : langTemplate[0].query;
    const selectCountry: TranslationProps = translate(langSelect);

    let lengthUnit = !lengthUnitTemplate.length ? 'meter' : lengthUnitTemplate[0].query;

    const defaultState = {
      country: selectCountry,
      user: {
        isGrafanaAdmin: userPath.isGrafanaAdmin,
        orgName: userPath.orgName,
        orgRole: userPath.orgRole,
      },
      datasources: {
        influx: {},
        mysqlFFT: {},
        json: {},
      },
      theme: currentTheme,
      lang: langSelect,
      beacon: macAddress,
      fft: [],
      firstSessionValues: [],
      secondSessionValues: [],
      firstSessionNb: '',
      secondSessionNb: '',
      currentHandle: {
        settings: {
          time: '',
          lower_freq: '',
          upper_freq: '',
          handle: '',
          freqFields: [],
          compr_type: '',
          sensor: '',
          orientation: '',
          spectrum_type: '',
          spectrum_param: '',
        },
        beaconInfos: [],
      },
      listIsLoading: false,
      sessionsIsLoading: false,
      errors: {
        loadSessions: false,
        selectedSession: false,
        maxFft: false,
      },
      highlightTime: '',
      isLog: false,
      isMms: false,
      isGUnit: false,
      isRpm: false,
      fftSessionRef: [],
      currentProfile: '',
      allProfiles: [],
      allTags: {
        tags: [],
        isTagsLoaded: false,
      },
      isModalOpen: false,
      timeZone: '',
      lengthUnit: lengthUnit,
    };
    const [initialState] = React.useState<AppState>(defaultState);

    return <WrappedComponent initialState={initialState}>{children}</WrappedComponent>;
  };
};
